import { API, logError } from "./index";

const getFavorite = async (filters) => {
    let URL = `/favorite?page=${filters.page}`;
    if (filters.speciality) URL = `${URL}&speciality_id=${filters.speciality}`;
    if (filters.partner) URL = `${URL}&partner_id=${filters.partner}`;
    if (filters.time) URL = `${URL}&time=${filters.time}`;

    try {
        return await API().get(URL);
    } catch (error) {
        return logError(error);
    }
};

const storeFavorite = async (data) => {
    try {
        return await API().post('/favorite', data);
    } catch (error) {
        return logError(error);
    }
};

export {
    getFavorite,
    storeFavorite
};